// 茶产业
import SHZT from './sn-table-column-shzt';
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ND',
                label: '年份',
                minWidth: 100,
                showoverflowtooltip: true
            },
            {
                prop: 'CYCL',
                label: '茶叶产量(公斤)',
                minWidth: 140,
                showoverflowtooltip: true
            }, {
                prop: 'CYZL',
                label: '茶叶质量',
                minWidth: 100,
                substr: true
            },
            {
                prop: 'CYPZ',
                label: '茶叶品种',
                minWidth: 100,
                substr: true
            }, {
                prop: 'CYJG',
                label: '茶叶价格(元)',
                minWidth: 100
            }, {
                prop: 'CYSY',
                label: '茶叶收益(元)',
                minWidth: 100
            }, {
                prop: 'CYRY',
                label: '茶产业从业人员数量(人)',
                minWidth: 100
            }, {
                prop: 'HZSSL',
                label: '合作社数量',
                minWidth: 100
            }, {
                prop: 'SCGM',
                label: '生产规模',
                minWidth: 100
            },
            {
                prop: 'RYSL',
                label: '人员总数',
                minWidth: 100
            },
            {
                prop: 'PP',
                label: '品牌',
                minWidth: 100
            },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [],
        searchBtn: {
            // buttonData: [{
            //     btnType: 'button',
            //     operateType: 'conditionSearchBtn',
            //     name: '高级查询',
            //     round: true,
            //     backColor: '#2a63d5',
            //     color: '#fff'
            // }]
        }
    }
};
export default selfData;